<template>
  <div class="newdevice">
    <div class="modal">
      <div class="modal-wrapper">
        <div class="header"><h2>New Device Detected</h2></div>

        <div class="modal-content">
          <p>
            You have logged into your account on a new device for the first
            time.<br /><br />
            Please check your email to approve the new device for use or click
            the button below to resend the email.
          </p>
        </div>
        <div class="modal-footer">
          <button class="button" @click="sendDeviceApprovalEmail">
            Resend Email
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import DeviceDetector from "device-detector-js";
import {
  timestamp,
  auth,
  rtdb,
  db,
  analytics,
} from "../components/firebase/firebaseInit";
export default {
  name: "Forgot Password",
  data() {
    return {
      email: "",
      modalActive: false,
      modalMessage: "",
      loading: null,
    };
  },
  components: {},
  created() {
    console.log(this.$store.state.deviceId);
    let unsubscribe = db
      .collection("users")
      .doc(this.$store.state.profileId)
      .collection("approvedDevices")
      .where("deviceId", "==", this.$store.state.deviceId)
      .onSnapshot((querySnapshot) => {
        console.log("NO MATCH: ", querySnapshot.empty);

        if (querySnapshot.empty) {
          return;
        } else if (querySnapshot.empty == false) {
          this.$router.replace("/browse");

          unsubscribe();
        }
      });
  },
  methods: {
    async sendDeviceApprovalEmail() {
      let deviceDetector = new DeviceDetector();
      const userAgent = navigator.userAgent;
      this.currentDevice = deviceDetector.parse(userAgent);
      let sentDate = Date.now();
      let mailID = sentDate + this.$store.state.profileEmail + "-ndd";
      db.collection("mail")
        .doc(mailID)
        .set({
          sentDate: sentDate,
          to: this.$store.state.profileEmail,
          userId: this.$store.state.profileId,
          expired: false,
          newDeviceId: this.$store.state.deviceId,
          template: {
            name: "newdevice",
            data: {
              fullName:
                this.$store.state.profileFirstName +
                " " +
                this.$store.state.profileLastName,
              registrationLink:
                "https://sstream.app/device-approval?id=" + mailID,
              deviceType: this.currentDevice.device.type,
              deviceBrand: this.currentDevice.device.brand,
              deviceOS: this.currentDevice.os.name,
              deviceBrowser: this.currentDevice.client.name,
              deviceBrowserVer: this.currentDevice.client.version,
            },
          },
        })
        .then(() => {
          console.log("NEW DEVICE EMAIL SENT");
          this.$router.replace("newdevice");
        });
    },
    closeModal() {
      this.modalActive = !this.modalActive;
      this.email = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.newdevice {
  display: flex;
  justify-items: center;
  align-items: center;
  background-image: url("../assets/loginbackground.jpg");
  max-width: 100vw;
  height: 100%;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);

  .modal-wrapper {
    display: flex;
    flex-direction: column;
    width: 580px;
    background-color: #383c49;

    .header {
      background-color: #383c49;
      height: 64px;
      display: flex;
      align-items: center;
      color: #e5ecff;
      font-size: 1.5em;
      font-weight: 700;
      padding-left: 25px;
      border-bottom: 1px solid RGB(38, 40, 48);

      p {
        text-align: center;
      }

      button {
        align-self: center;
      }
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      padding: 15px 15px;
      text-align: center;
      height: 100%;
      align-items: center;
      background-color: RGB(68, 75, 90);
      border: solid #383c49;
      border-width: 20px 20px 20px 20px;

      h2 {
        margin: 0px;
      }

      p {
        width: 350px;
      }
    }
    .modal-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: auto;
      background-color: RGB(47, 50, 60);
      width: 100%;
      height: 64px;
      flex-shrink: 0;
      border-top: 1px solid RGB(38, 40, 48);

      .button {
        position: relative;
        padding: 0px 15px;
        max-height: 37px;
        min-height: 33px;
        font-size: 12px;
        font-weight: 700;
        background-color: RGBA(68, 75, 90, 0.2);
        border: 1px solid #444b5a;
        color: inherit;
        cursor: pointer;
        min-width: 33px;
        margin-left: 10px;
        text-transform: uppercase;
        margin-right: 20px;
      }

      .button:hover {
        background-color: #505874;
      }
    }
  }
}
</style>